/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    pre: "pre",
    code: "code",
    ol: "ol",
    strong: "strong",
    h3: "h3",
    h4: "h4"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-2-cube-conundrum"
  }, "Day 2: Cube Conundrum"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2023/day/2"
  }, "https://adventofcode.com/2023/day/2")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2023/solutions/src/day_02.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "You arrive at a sky island and an elf comes to greet you.\nThey want to play a game while you walk to your destination."), "\n", React.createElement(_components.p, null, "In a small bag, there are an unknown amount of coloured cubes.\nThe cubes are all either red, green, or blue."), "\n", React.createElement(_components.p, null, "Each round, the elf draws some cubes from the bag and shows them to you."), "\n", React.createElement(_components.p, null, "The goal of the game is to figure out which cubes are in the bag."), "\n", React.createElement(_components.p, null, "The input today is a list of game-reports."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Each line describes one game."), "\n", React.createElement(_components.li, null, "Each round (that lists the cubes the elf drew from the bag) is separated by a semicolon."), "\n"), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "Game 1: 3 blue, 4 red; 1 red, 2 green, 6 blue; 2 green\nGame 2: 1 blue, 2 green; 3 green, 4 blue, 1 red; 1 green, 1 blue\nGame 3: 8 green, 6 blue, 20 red; 5 blue, 4 red, 13 green; 5 green, 1 red\nGame 4: 1 green, 3 red, 6 blue; 3 green, 6 red; 3 green, 15 blue, 14 red\nGame 5: 6 red, 1 blue, 3 green; 2 blue, 1 red, 2 green\n")), "\n", React.createElement(_components.p, null, "That means in game 1 there were 3 draws:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "3 blue cubes, and 4 red cubes"), "\n", React.createElement(_components.li, null, "1 red cube, 2 green cubes, and 6 blue cubes"), "\n", React.createElement(_components.li, null, "2 green cubes"), "\n"), "\n", React.createElement(_components.p, null, "After a draw is shown, all cubes are placed back into to bag before drawing from the bag again."), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "The elf wants to know which games in your input would have been possible if the bag contained only ", React.createElement(_components.strong, null, "12 red cubes, 13 green cubes, and 14 blue cubes"), "."), "\n", React.createElement(_components.p, null, "The question asks for the sum of each possible game ID."), "\n", React.createElement(_components.p, null, "In the example, game 1, 2, and 5 would have been possible, summing those IDs gives 8."), "\n", React.createElement(_components.h3, {
    id: "option-1-nested-for-loops"
  }, "Option 1: Nested for loops"), "\n", React.createElement(_components.p, null, "I decided to build up the sum while iterating through the lines of my input."), "\n", React.createElement(_components.p, null, "Some skeleton/pseudocode:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut sum = 0;\n\nfor line in input.lines() {\n    let id = // id of game;\n    let draws = // list of draws in the current game\n\n    for draw in draws {\n        // check if a draw is possible\n    }\n    if all_draws_were_possible {\n        sum += id;\n    }\n}\n\nsum\n")), "\n", React.createElement(_components.p, null, "Starting to fill out that skeleton:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn part_1(input: &str) -> usize {\n    let mut sum = 0;\n    for (idx, line) in input.lines().enumerate() {\n        let id = idx + 1;\n        let (_, draws) = line.split_once(\": \").unwrap();\n        for draw in draws.split(\"; \") {\n            // check if a draw is possible\n        }\n        if all_draws_were_possible {\n            sum += id;\n        }\n    }\n    sum\n}\n")), "\n", React.createElement(_components.p, null, "For each draw, several number/color pairs can be shown (ie: 3 blue, 4 red).\nI decided to implement a check to see if a certain pair was possible with the given cubes."), "\n", React.createElement(_components.p, null, "If it’s not, I skip to the next game.\nIf all pairs in the current game are possible however, I increment the sum."), "\n", React.createElement(_components.h4, {
    id: "code"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs"
  }, "pub fn part_1(input: &str) -> usize {\n    let mut sum = 0;\n    'game: for (idx, line) in input.lines().enumerate() {\n        let id = idx + 1;\n        let (_, draws) = line.split_once(\": \").unwrap();\n        for draw in draws.split(\"; \") {\n            for pair in draw.split(\", \") {\n                let (num, color) = pair.split_once(\" \").unwrap();\n                let num: u32 = num.parse().unwrap();\n                let possible = match color {\n                    \"red\" => num <= 12,\n                    \"green\" => num <= 13,\n                    \"blue\" => num <= 14,\n                    _ => panic!(\"at the disco\"),\n                };\n                if !possible {\n                    // a check failed, move on to next game\n                    continue 'game;\n                }\n            }\n        }\n        // all checks in this game passed, add to sum\n        sum += id;\n    }\n    sum\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-an-iterator-chain"
  }, "Option 2: An iterator chain"), "\n", React.createElement(_components.p, null, "A more organized, but slower solution."), "\n", React.createElement(_components.p, null, "This involves parsing each game first, then filtering out impossible games, and finally summing up the IDs of every remaining game."), "\n", React.createElement(_components.p, null, "In skeleton/pseudocode:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "input\n    .lines()\n    .map(/* turn a line into a Game */)\n    .filter(/* filter out Games that are impossible */)\n    .map(/* get id of remaining Games */)\n    .sum()\n")), "\n", React.createElement(_components.h4, {
    id: "helpers"
  }, "Helpers"), "\n", React.createElement(_components.p, null, "Each line represents a ", React.createElement(_components.code, null, "Game"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "struct Game {\n    id: usize,\n    draws: Vec<Draw>,\n}\n")), "\n", React.createElement(_components.p, null, "Within a game, several draws happen, represented by a list of ", React.createElement(_components.code, null, "Draw"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "struct Draw {\n    red: u32,\n    green: u32,\n    blue: u32,\n}\n")), "\n", React.createElement(_components.p, null, "Each draw has a number of red, green, and blue cubes.\nIf no cubes of a color were drawn, the value of the field is 0."), "\n", React.createElement(_components.p, null, "I created a way to turn strings like “3 blue, 4 red”, or “1 red, 2 green, 6 blue” into a ", React.createElement(_components.code, null, "Draw"), " struct."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "impl Draw {\n    fn new(s: &str) -> Draw {\n        s.split(\", \").fold(\n            Draw {\n                red: 0,\n                green: 0,\n                blue: 0,\n            },\n            |mut acc, item| {\n                let (num, color) = item.split_once(\" \").unwrap();\n                let num = num.parse().unwrap();\n                match color {\n                    \"red\" => acc.red = num,\n                    \"green\" => acc.green = num,\n                    \"blue\" => acc.blue = num,\n                    _ => panic!(\"at the disco\"),\n                };\n                acc\n            },\n        )\n    }\n}\n")), "\n", React.createElement(_components.p, null, "That way, turning each line into a ", React.createElement(_components.code, null, "Game"), " looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "input\n    .lines()\n    .enumerate()\n    .map(|(idx, line)| {\n        let (_, draws) = line.split_once(\": \").unwrap();\n        let draws = draws.split(\"; \").map(Draw::new).collect();\n        Game { id: idx + 1, draws }\n    })\n")), "\n", React.createElement(_components.p, null, "All that is left then, is to filter out invalid games, and sum the remaining games’ IDs."), "\n", React.createElement(_components.h4, {
    id: "code-1"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs"
  }, "struct Game {\n    id: usize,\n    draws: Vec<Draw>,\n}\n\nstruct Draw {\n    red: u32,\n    green: u32,\n    blue: u32,\n}\n\nimpl Draw {\n    fn new(s: &str) -> Draw {\n        s.split(\", \").fold(\n            Draw {\n                red: 0,\n                green: 0,\n                blue: 0,\n            },\n            |mut acc, item| {\n                let (num, color) = item.split_once(\" \").unwrap();\n                let num = num.parse().unwrap();\n                match color {\n                    \"red\" => acc.red = num,\n                    \"green\" => acc.green = num,\n                    \"blue\" => acc.blue = num,\n                    _ => panic!(\"at the disco\"),\n                };\n                acc\n            },\n        )\n    }\n}\n\nfn part_1(input: &str) -> usize {\n    input\n        .lines()\n        .enumerate()\n        .map(|(idx, line)| {\n            let (_, draws) = line.split_once(\": \").unwrap();\n            let draws = draws.split(\"; \").map(Draw::new).collect();\n            Game { id: idx + 1, draws }\n        })\n        .filter(|game| {\n            game.draws\n                .iter()\n                .all(|draw| draw.red <= 12 && draw.green <= 13 && draw.blue <= 14)\n        })\n        .map(|game| game.id)\n        .sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "As you continue your walk, the Elf poses a second question:\nin each game you played, what is the fewest number of cubes of each color that could have been in the bag to make the game possible?"), "\n", React.createElement(_components.p, null, "The question asks for sum of the ", React.createElement(_components.strong, null, "power levels"), " of all games."), "\n", React.createElement(_components.p, null, "The power level can be found by multiplying the minimum amount of red, blue, and green cubes to make a game valid."), "\n", React.createElement(_components.p, null, "The setup looks very similar to part one."), "\n", React.createElement(_components.p, null, "This time, per game, we keep track of the minimum amount of cubes of a certain color."), "\n", React.createElement(_components.p, null, "If we come across a draw with a larger amount of cubes for that color, that minimum is increased."), "\n", React.createElement(_components.p, null, "Per game, the power level is then calculated and added to a sum."), "\n", React.createElement(_components.h3, {
    id: "code-2"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs"
  }, "\npub fn part_2(input: &str) -> u32 {\n    let mut sum = 0;\n    for line in input.lines() {\n        let mut min_red = 0;\n        let mut min_green = 0;\n        let mut min_blue = 0;\n        let (_, draws) = line.split_once(\": \").unwrap();\n        for draw in draws.split(\"; \") {\n            for pair in draw.split(\", \") {\n                let (num, color) = pair.split_once(\" \").unwrap();\n                let num: u32 = num.parse().unwrap();\n                match color {\n                    \"red\" => min_red = min_red.max(num),\n                    \"green\" => min_green = min_green.max(num),\n                    \"blue\" => min_blue = min_blue.max(num),\n                    _ => panic!(\"at the disco\"),\n                }\n            }\n        }\n        sum += min_red * min_green * min_blue;\n    }\n    sum\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_02.rs",
    numberLines: true
  }, "pub fn part_1(input: &str) -> usize {\n    let mut sum = 0;\n    'game: for (idx, line) in input.lines().enumerate() {\n        let id = idx + 1;\n        let (_, draws) = line.split_once(\": \").unwrap();\n        for draw in draws.split(\"; \") {\n            for pair in draw.split(\", \") {\n                let (num, color) = pair.split_once(\" \").unwrap();\n                let num: u32 = num.parse().unwrap();\n                let possible = match color {\n                    \"red\" => num <= 12,\n                    \"green\" => num <= 13,\n                    \"blue\" => num <= 14,\n                    _ => panic!(\"at the disco\"),\n                };\n                if !possible {\n                    // a check failed, move on to next game\n                    continue 'game;\n                }\n            }\n        }\n        // all checks in this game passed, add to sum\n        sum += id;\n    }\n    sum\n}\n\npub fn part_2(input: &str) -> u32 {\n    let mut sum = 0;\n    for line in input.lines() {\n        let mut min_red = 0;\n        let mut min_green = 0;\n        let mut min_blue = 0;\n        let (_, draws) = line.split_once(\": \").unwrap();\n        for draw in draws.split(\"; \") {\n            for pair in draw.split(\", \") {\n                let (num, color) = pair.split_once(\" \").unwrap();\n                let num: u32 = num.parse().unwrap();\n                match color {\n                    \"red\" => min_red = min_red.max(num),\n                    \"green\" => min_green = min_green.max(num),\n                    \"blue\" => min_blue = min_blue.max(num),\n                    _ => panic!(\"at the disco\"),\n                }\n            }\n        }\n        sum += min_red * min_green * min_blue;\n    }\n    sum\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
